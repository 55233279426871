import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0b3a64882cc8169450051b98b9f2cba3@o4506106153861120.ingest.us.sentry.io/4508213116928000",
  debug: false,
  environment: "staging",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.01,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});